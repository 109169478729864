<template>
  <v-card class="py-5 mb-5 px-5">
    <v-card-title>
      {{ label }}
    </v-card-title>
    <v-container class="py-0">
      <v-row align="center" justify="start">
        <v-col cols="12">
          <v-form @submit="formSubmit">
            <v-text-field
              v-model="newElement"
              @click:append="addNewItem"
              append-icon="mdi-plus"
              type="text"
              label="Añadir"
              full-width
            ></v-text-field>
          </v-form>
        </v-col>
      </v-row>
    </v-container>

    <v-list>
      <v-list-item v-for="(item, idx) in items" :key="idx">
        <v-list-item-title v-text="item[textField]"></v-list-item-title>
        <v-list-item-avatar>
          <a @click="deleteItem(idx)"
            ><v-icon color="red" v-text="'mdi-delete'"></v-icon
          ></a>
        </v-list-item-avatar>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => {
        [];
      }
    },
    textField: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  data: () => ({
    items: [],
    newElement: null
  }),
  mounted() {
    this.items = this.value;
  },
  computed: {},
  watch: {
    value(newValue) {
      this.items = newValue;
    }
  },

  methods: {
    deleteItem(idx) {
      this.items.splice(idx, 1);
      this.$emit("input", this.items);
    },
    addNewItem() {
      let newItem = {};
      newItem[this.textField] = this.newElement;
      this.items.push(newItem);
      this.newElement = null;
      this.$emit("input", this.items);
    },
    formSubmit(e) {
      e.preventDefault();
      this.addNewItem();
    }
  }
};
</script>

<style scoped>
.multiple-input-div {
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: gray;
}
</style>
